<template>
  <div class="chuangxing">
    <img :src="data.url + 'dealer/cxdfy/bg.png'" alt="" class="bg" />
    <div class="content">
      <div class="title">去布里斯班，体验 “布”一样的创星登峰游</div>
      <div class="video">
        <video
          ref="myVideo"
          width="100%"
          style="object-fit: contain"
          preload
          controls="controls"
          poster="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/cxdfy.png"
          src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/cxdfy/cxdfy.mp4"
        ></video>
      </div>
      <div class="downLoadBtn" @click="lookVideo">
        分享和下载视频
        <img :src="data.url + 'right.png'" alt="" />
      </div>
      <div class="row">
        <div class="col" @click="openPdf">
          <div class="coverImg">
            <img :src="data.url + 'dealer/cxdfy/activity.png'" alt="" />
          </div>
          <div class="nav">
            <div class="text">活动相册</div>
            <img :src="data.url + 'dealer/cxdfy/nav.png'" alt="" />
          </div>
        </div>
        <div class="col" @click="jumpPath">
          <div class="coverImg">
            <img :src="data.url + 'dealer/cxdfy/tuwen.png'" alt="" />
          </div>
          <div class="nav">
            <div class="text">图文回顾</div>
            <img :src="data.url + 'dealer/cxdfy/nav.png'" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="backBtn" @click="backIndex">
      <img :src="data.url + 'dealer/cxdfy/backBtn.png'" alt="" />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { prviewVideoBox } from "../../js/until";
import { reactive } from "vue";
// import { showToast } from "vant";
// import { baiduTotal } from "../js/common.js";
let data = reactive({
  url: "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/", //图片地址
});
const router = useRouter();
// 返回首页
let backIndex = () => {
  router.replace("/index");
};
//查看视频
let lookVideo = () => {
  prviewVideoBox({
    videoUrl:
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/cxdfy/cxdfy.mp4",
    qcodeUrl:
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/cxdfy/cx_qr.png",
    poster:
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/cxdfy.png",
    wangpanPath:
      "https://nudrive.cn.nuskin.com/file/en0flmarggfpinprzbfosjf2f3zgjiww#",
  });
};
//页面跳转
let jumpPath = () => {
  window.location.href = "https://mp.weixin.qq.com/s/W_U-peBCB1GQHerTZRKg7A";
};
//活动相册
let openPdf = () => {
  window.location.href = "https://live.photoplus.cn/live/pc/25833948/#/live";
};
</script>

<style lang="scss" scoped>
.chuangxing {
  width: 100%;
  height: 100%;
  position: relative;
  background: #62b0e2;
  box-sizing: border-box;
  overflow-y: auto;
  .bg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .content {
    position: relative;
    width: 100%;
    padding-top: 2.14rem;
    .title {
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }
    .video {
      margin-top: 0.2rem;
      width: 100%;
    }
    .downLoadBtn {
      position: relative;
      width: 1.52rem;
      height: 0.4rem;
      margin-top: 0;
      border: 1px solid rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-family: PingFang SC;
      font-size: 0.12rem;
      font-style: normal;
      font-weight: 400;
      border-radius: 0.2rem;
    }
    .row {
      width: 100%;
      padding: 0 0.12rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.2rem;
      .col {
        width: 1.7rem;
        box-shadow: 0 0.04rem 0.1rem rgba($color: #f08e56, $alpha: 0.3);
        .coverImg {
          width: 100%;
          font-size: 0;
          img {
            width: 100%;
            border-radius: 0.08rem 0.08rem 0 0;
          }
        }
        .nav {
          width: 100%;
          font-size: 0;
          background: #f08e56;
          border-radius: 0 0 0.08rem 0.08rem;
          overflow: hidden;
          position: relative;
          .text {
            position: absolute;
            top: 50%;
            left: 0.16rem;
            transform: translateY(-50%);
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 12px;
            color: #ffffff;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
