<template>
  <div class="videoView">
    <div class="mask" @click="closeDia"></div>
    <div
      class="imgWrap"
      style="
        top: 44%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <video
        ref="myVideo"
        width="100%"
        style="object-fit: contain"
        preload
        :src="props.videoUrl"
        controls="controls"
        :poster="props.poster"
      ></video>
      <div class="qcodeBtn" v-if="props.qcodeUrl">
        <img :src="props.qcodeUrl" alt="" />
        <div class="qcodeText">长按识别二维码<br />查看、分享视频</div>
      </div>
    </div>
    <div class="bottomArea" @click="closeDia">
      <div v-if="props.wangpanPath" class="imgBtn" @click.stop="openPath">
        <img
          src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/downLoad.png"
          alt=""
        />
        点击下载完整视频
      </div>
      <div class="remarkInfo">点击任意空白区域返回</div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, onMounted } from "vue";
let props = defineProps({
  qcodeUrl: {
    type: String,
    default: "",
  },
  wangpanPath: {
    type: String,
    default: "",
  },
  videoUrl: {
    type: String,
    default: "",
  },
  poster: {
    type: String,
    default: "",
  },
  close: {},
});
//打开网盘
let openPath = () => {
  window.location.href = props.wangpanPath;
};
//关闭组件
let closeDia = () => {
  props.close();
};
onMounted(() => {});
</script>
<style lang="scss" scoped>
.videoView {
  width: 100%;
  height: 100%;
  .imgWrap {
    width: 100%;
    max-height: 6rem;
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    video {
      width: 100%;
      max-height: 4.6rem;
    }
  }
  .qcodeBtn {
    width: 1.8rem;
    padding: 0.08rem;
    border-radius: 0.08rem;
    border: 2px solid rgba(190, 223, 236, 0.5);
    background: radial-gradient(
        46.42% 4.69% at 42.9% 0%,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(
        102.84% 100% at 50% 0%,
        rgba(122, 189, 228, 0.8) 0%,
        rgba(76, 104, 149, 0.7) 100%
      );
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0.1rem auto 0;
    img {
      width: 0.6rem;
      height: 0.6rem;
      margin-right: 0.1rem;
    }
    .qcodeText {
      font-size: 0.14rem;
      font-weight: 500;
      color: #fff;
      border-radius: 6px;
      line-height: 0.24rem;
    }
  }
  .bottomArea {
    width: 100%;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0.2rem;
    .remarkInfo {
      text-align: center;
      margin-top: 6px;
      font-size: 0.1rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
    }
    .imgBtn {
      width: 1.48rem;
      height: 0.4rem;
      border: 1px solid #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.24rem;
      font-size: 0.12rem;
      font-weight: 400;
      color: #ffffff;
      margin: 0 auto;
      img {
        height: 0.16rem;
        margin-right: 4px;
      }
    }
  }
}
</style>
