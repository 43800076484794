import {createApp} from 'vue';

import preImg from "../components/preImg.vue";
import prviewVideo from "../components/preVideo.vue";
//图片组件
export function preImgBox(data){
  const div= document.createElement('div');
  div.className='dialog';
  document.getElementById('app').appendChild(div);
  const app = createApp(preImg,{
    imgList:data.imgList,
    diskUrl:data.diskUrl,
    close:()=>{
      app.unmount();
      div.remove()
    }
  });
  app.mount(div);
}
export function prviewVideoBox(data){
  const div= document.createElement('div');
  div.className='dialog';
  document.body.appendChild(div);
  const app = createApp(prviewVideo,{
    videoUrl:data.videoUrl,
    qcodeUrl:data.qcodeUrl,
    wangpanPath:data.wangpanPath,
    close:()=>{
      app.unmount();
      div.remove()
    }
  });
  app.mount(div);
  
}